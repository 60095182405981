import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Head from 'next/head';
import {
  Box,
  HeaderImage,
  HeroNFTV2,
  HorizontalDivider,
} from '@ttx/design-system';
import {useRouter} from 'next/router';
import {GetServerSideProps} from 'next';
import {useBreakpoint} from '@ttx/design-system/src/use-breakpoint';
import {NextPageWithPageLayoutOptions} from '../components/page-layout/types';
import {useTtxMutation} from '../hooks/ttx-trpc';
import {NFTTrackHorizontalScroll} from '../components/nft-track-horizontal-scroll';
import {SEO} from '../components/seo';
import {WhatWeDoSection} from '../components/home-page-components/what-we-do-section';
import {LearnMoreSection} from '../components/home-page-components/learn-more-section';
import {MarketPlaceSection} from '../components/home-page-components/market-place-section';
import {DocumentationSection} from '../components/home-page-components/documentation-section';
import {HomePageConatiner} from '../components/home-page-components/container-section';
import {ConnectWithUsSection} from '../components/home-page-components/connect-with-us-section';
import {SubscriptionSection} from '../components/home-page-components/subscription-section';
import {HomeHeaderSection} from '../components/home-page-components/home-header-section';
import {BCWrapper} from '../components/blockchain-props-wrapper/bc-props.wrapper';
import {PressReleaseSection} from '../components/home-page-components/press-release-section';

const IndexPage: NextPageWithPageLayoutOptions = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {isLoading, error, ...collectionListMutation} = useTtxMutation(
    'collection.hero-nft',
  );

  const router = useRouter();

  useEffect(() => {
    router.push('/artists');
    return;
    collectionListMutation.mutate({
      collectionStatus: [1, 2],
      nftStatus: [1, 2],
      includeInActive: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const latestCollection = useMemo(
    () => collectionListMutation.data || null,
    [collectionListMutation.data],
  );

  const data = useMemo(
    () =>
      latestCollection
        ? latestCollection?.nftTokenIds?.map((item) => ({
            ...item,
            network: latestCollection.blockchainNetworkDetail.network,
          }))
        : [],
    [latestCollection],
  );

  const creatorName = latestCollection?.creatorDetails.user.displayName || '';
  const dropName = data?.[0]?.properties.artist;

  const artistSlug = latestCollection?.creatorDetails?.user?.slug || '';

  // New Home Page
  const [isMobile, setIsMobile] = useState(false);
  const {currentBreakpoint} = useBreakpoint();
  useEffect(() => {
    setIsMobile(currentBreakpoint === '768px');
  }, [currentBreakpoint]);
  const whatWeDoSectionRef = useRef(null);

  const scrollToRef = useCallback((scrollRef: React.MutableRefObject<null>) => {
    if (!scrollRef || !scrollRef.current) return;
    (scrollRef.current as any).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);

  const reDirectToPage = (url: string) => {
    router.push(url);
  };
  // End New Home Page

  const renderHeroNFT = useCallback(() => {
    if (data.length === 0) return null;
    if (data.length > 1)
      return (
        <NFTTrackHorizontalScroll
          isLoading={isLoading}
          data={data}
          network={latestCollection?.blockchainNetworkDetail.network}
          multiCopy={!!latestCollection?.multiCopy}
          creatorName={dropName}
          type={latestCollection?.type}
        />
      );

    let key = 'tracks';
    if (latestCollection?.type === 5) {
      key = 'traxxstemz';
    } else if (latestCollection?.type === 7) {
      key = 'emilg';
    }
    return (
      <BCWrapper
        nftToken={data[0]}
        multiCopy={latestCollection?.multiCopy || false}
        network={data[0].network}
        key={`/${key}/${data[0]._id}`}
        type={latestCollection?.type}
      >
        <HeroNFTV2
          image={data[0].image}
          header={dropName}
          subheader={data[0].name}
          description={data[0].description}
          onPressOverride={() => router.push(`/${key}/${data[0].slug}`)}
          buttonCta="Buy now"
          artistHandle={data[0].properties.artist}
          dateAdded={new Date(data[0].createdAtUTC)}
        />
      </BCWrapper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestCollection]);

  return (
    <>
      <Head>
        <title>Home</title>
      </Head>
      <SEO url={`${props.url}`} title="Home" />
      <Box p="md">
        <HomeHeaderSection
          handleWhatWeDoClick={() => scrollToRef(whatWeDoSectionRef)}
          handleRedirect={reDirectToPage}
          isMobile={isMobile}
        />
      </Box>
      <HorizontalDivider color="grey12" />
      <Box ref={whatWeDoSectionRef}>
        <HomePageConatiner isMobile={isMobile}>
          <WhatWeDoSection
            handleRedirect={reDirectToPage}
            isMobile={isMobile}
          />
        </HomePageConatiner>
      </Box>
      <HorizontalDivider color="grey12" />
      <HomePageConatiner isMobile={isMobile}>
        <PressReleaseSection isMobile={isMobile} />
      </HomePageConatiner>
      <HorizontalDivider color="grey12" />
      <HomePageConatiner isMobile={isMobile}>
        <LearnMoreSection isMobile={isMobile} handleRedirect={reDirectToPage} />
      </HomePageConatiner>
      <HorizontalDivider color="grey12" />
      <HomePageConatiner isMobile={isMobile}>
        <MarketPlaceSection
          isMobile={isMobile}
          handleRedirect={reDirectToPage}
        />
      </HomePageConatiner>
      <HomePageConatiner isMobile={isMobile} topZero>
        <HeaderImage
          name={creatorName}
          disabled={!artistSlug}
          href={`/artists/${artistSlug}`}
        />
      </HomePageConatiner>
      {renderHeroNFT()}
      <HorizontalDivider color="grey12" />
      <HomePageConatiner isMobile={isMobile}>
        <DocumentationSection
          isMobile={isMobile}
          handleRedirect={reDirectToPage}
        />
      </HomePageConatiner>
      <HorizontalDivider color="grey12" />
      <HomePageConatiner isMobile={isMobile}>
        <SubscriptionSection isMobile={isMobile} />
      </HomePageConatiner>
      <HorizontalDivider color="grey12" />
      <HomePageConatiner isMobile={isMobile}>
        <ConnectWithUsSection isMobile={isMobile} />
      </HomePageConatiner>
      <HorizontalDivider color="grey12" />
    </>
  );
};

IndexPage.pageLayoutOptions = {
  showFooter: true,
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      url: context?.req?.headers?.host,
    },
  };
};

export default IndexPage;
